import IndustryPageUpdated from "components/industries/updated/IndustryPageUpdated";
import GlobalLayout from "components/page/GlobalLayout";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

export default function ManufacturingIndustry() {
  const renderContent = data => {
    const pageData = {
      industry: "Storage and Warehouse",
      header: {
        headerTitle: "Remotely Manage Facilities in Real Time from Anywhere",
        headerParagraph:
          "Revolutionize the way you do business with cloud-based security that can scale with you. Improve storage and warehouse operations, enhance productivity, and always have a clear line of sight into operations.",
        headerImg: data.headerImage,
        mobileHeaderImg: data.headerImageMobile,
      },
      industryTitle: "Trusted by Storage and Warehouse Leaders",
      industryLogos: [
        {
          img: data.logo1,
          width: "145px",
          alt: "StorQuest",
        },
        {
          img: data.logo2,
          width: "138px",
          alt: "Federal Self Storage",
        },
        {
          img: data.logo3,
          width: "142px",
          alt: "columbia warehouse group",
        },
        {
          img: data.logo4,
          width: "115.37px",
          alt: "American seld storage",
        },
        {
          img: data.logo5,
          width: "94px",
          alt: "Rise",
        },
        {
          img: data.logo6,
          width: "115px",
          alt: "Yellow supply",
        },
      ],
      features: [
        {
          icon: data.icon1,
          iconAlt: "shield",
          title: "Improve Building Security",
          p:
            "Deploy unlimited devices across all locations to eliminate blind spots and acquire real-time visibility from a centralized web console and mobile app.",
        },
        {
          icon: data.icon2,
          iconAlt: "alert",
          title: "Eliminate False Alarms",
          p:
            "Integrated alarms and video monitoring verifies alerts and dispatches emergency services based on suspicious activity, unusual behavior, and more.",
        },
        {
          icon: data.icon3,
          iconAlt: "detection",
          title: "Reduce Theft and Vandalism",
          p:
            "Leverage powerful AI analytics to detect, deter, and investigate acts of theft or vandalism and share footage with local emergency services.",
        },
        {
          icon: data.icon4,
          iconAlt: "temperature",
          title: "Maintain Optimal Environments",
          p:
            "Protect assets with IoT sensors and real-time alerts for temperature irregularities, unhealthy working conditions, unauthorized access, and more.",
        },
      ],
      learnRows: [
        {
          image: data.learnImage1,
          title: "Protect Staff, Property, and Assets",
          description:
            "Secure storage and warehouse spaces 24/7/365 with cloud-based physical security. Leverage native remote access and real-time AI alerts to proactively detect threats and prevent theft, trespassing, and vandalism across all facilities. ",
          linkText: "Learn About Cloud-Based Cameras",
          linkUrl: "/cameras",
        },
        {
          image: data.learnImage2,
          title: "Accelerate Emergency Response and Eliminate False Alarms",
          description:
            "Receive intelligent security alerts and use video verification to quickly escalate or dismiss potential emergencies. Relay key details to law enforcement and enhance response with person-of-interest detection, panic buttons, and alarm monitoring.",
          linkText: "Learn About Alarm Monitoring",
          linkUrl: "/alarm-monitoring/",
        },
        {
          image: data.learnImage3,
          title: "Gain Insight into Environment Conditions with Smart Sensors",
          description:
            "Protect employees, equipment, and assets from hazardous environmental changes. Receive instant notifications for temperature fluctuations or when smoke, vape, or toxic air quality is detected.",
          linkText: "Learn About IoT Sensors",
          linkUrl: "/sensors",
        },
        {
          image: data.learnImage4,
          title: "Quickly Communicate with Staff or Customers from Anywhere",
          description:
            "Pair audio with cameras to get the full picture. Easily broadcast messages and alarms at scale, and remotely communicate with employees or customers onsite through two-way audio.",
          linkText: "Learn About Audio Gateways",
          linkUrl: "/sensors/a100/",
        },
        {
          image: data.learnImage5,
          title: "Stay in Control of Access Points",
          description:
            "Regulate entry and exit points and ensure only authorized personnel have access to restricted areas. Prevent potential security breaches or accidents by monitoring and controlling who has access and when with unfied access control.",
          linkText: "Learn About Access Control",
          linkUrl: "/access-control",
        },
      ],
      industryUseCases: [
        {
          title: "Rhombus in Self-Storage Facilities",
          tabTitle: "Self-Storage Facilities",

          description:
            "Safeguard customer belongings, deter theft, reduce false alarms, and streamline security management across all facilities. ",
          image: data.useCase1,
          mobileImage: data.useCaseMobile1,
        },
        {
          title: "Rhombus in Inventory Warehouses",
          tabTitle: "Inventory Warehouses",

          description:
            "Minimize losses due to unauthorized access, ensure accurate inventory management, and maintain a secure environment 24/7.",
          image: data.useCase2,
          mobileImage: data.useCaseMobile2,
        },
        {
          title: "Rhombus in Hazmat Warehouses",
          tabTitle: "Hazmat Warehouses",
          description:
            "Reduce the risk of accidents or damage, ensure compliance with safety regulations, and protect employees’ well-being.",
          image: data.useCase3,
          mobileImage: data.useCaseMobile3,
        },
      ],
      form: {
        title: "Request Demo",
        id: "fa7fb89b-ef66-4638-aa6e-e35b012fc6c6",
        paragraph:
          "Contact our team today and see why Rhombus is the leading physical security solution for businesses of all sizes.",
        caseStudy: {
          title: "Rhombus is Trusted by Storage and Warehouse Leaders",
          image: data.qouteImage,
          logo: {
            img: data.logo2,
            width: "122px",
            alt: "10 federal",
          },
          link: "/case-studies/10-federal/",
          author: {
            avatar: data.avatar,
            name: "Brad Minsley",
            role: "Managing Director at 10 Federal",
            qoute:
              "“Rhombus has allowed us to remotely view any property, be alerted to specific activities, <strong>better serve our customers</strong>, actively control building access, and more.”",
          },
        },
      },
    };
    return (
      <GlobalLayout color="var(--gradient-light)">
        <Helmet>
          <title>
            Rhombus for Self-Storage and Warehouse - Simplify Operations
          </title>
          <meta
            name="description"
            content="Discover how Rhombus smart security cameras, sensors, access control, and alarms improve security and visibility for leading self-storage and warehouse organizations."
          />
        </Helmet>

        <IndustryPageUpdated data={pageData} />
      </GlobalLayout>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: {
          eq: "components/industries/updated/img/storage-and-warehouse/storage-warehouse-hero-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, quality: 100, width: 668)
        }
      }
      headerImageMobile: file(
        relativePath: {
          eq: "components/industries/updated/img/storage-and-warehouse/storage-warehouse-hero-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, quality: 100, width: 350)
        }
      }
      logo1: file(
        relativePath: {
          eq: "components/industries/img/storage-and-warehouse/logo1-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo2: file(
        relativePath: {
          eq: "components/industries/img/storage-and-warehouse/logo2-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo3: file(
        relativePath: {
          eq: "components/industries/img/storage-and-warehouse/logo3-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo4: file(
        relativePath: {
          eq: "components/industries/img/storage-and-warehouse/logo4-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo5: file(
        relativePath: {
          eq: "components/industries/img/storage-and-warehouse/logo5-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo6: file(
        relativePath: {
          eq: "components/industries/img/storage-and-warehouse/logo6-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      icon1: file(
        relativePath: { eq: "components/industries/img/icons/shield.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon2: file(
        relativePath: { eq: "components/industries/img/icons/siren.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon3: file(
        relativePath: { eq: "components/industries/img/icons/detection.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon4: file(
        relativePath: { eq: "components/industries/img/icons/temperature.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      learnImage1: file(
        relativePath: {
          eq: "components/industries/updated/img/storage-and-warehouse/cameras-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      learnImage2: file(
        relativePath: {
          eq: "components/industries/updated/img/storage-and-warehouse/alarm-monitoring-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      learnImage3: file(
        relativePath: {
          eq: "components/industries/updated/img/storage-and-warehouse/environmental-monitoring-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      learnImage4: file(
        relativePath: {
          eq: "components/industries/updated/img/storage-and-warehouse/a100-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      learnImage5: file(
        relativePath: {
          eq: "components/industries/updated/img/storage-and-warehouse/access-control-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCase1: file(
        relativePath: {
          eq: "components/industries/updated/img/storage-and-warehouse/self-storage-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCase2: file(
        relativePath: {
          eq: "components/industries/updated/img/storage-and-warehouse/inventory-warehouse-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCase3: file(
        relativePath: {
          eq: "components/industries/updated/img/storage-and-warehouse/hazmat-warehouse-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCaseMobile1: file(
        relativePath: {
          eq: "components/industries/updated/img/storage-and-warehouse/mobile-self-storage-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCaseMobile2: file(
        relativePath: {
          eq: "components/industries/updated/img/storage-and-warehouse/mobile-inventory-warehouse-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCaseMobile3: file(
        relativePath: {
          eq: "components/industries/updated/img/storage-and-warehouse/mobile-hazmat-warehouse-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      qouteImage: file(
        relativePath: {
          eq: "components/industries/updated/img/storage-and-warehouse/10-federal-qoute-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      avatar: file(
        relativePath: {
          eq: "components/industries/updated/img/case-studies/brad-minsley.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;

  return <StaticQuery query={query} render={renderContent} />;
}
